.payment-status {
  .box-section-content {
    display: flex;
    flex-direction: row;

    p {
      position: relative;
      padding-left: 35px;
      display: block;
      font-weight: bold;
      margin: 0;

      &.green:after {
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 50%;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: radial-gradient(at top right, rgb(116, 255, 125) 0%, rgb(20, 180, 8) 60%, rgb(3, 96, 17) 120%);
        border: 2px #fff solid;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
      }

      &.orange:after {
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 50%;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: radial-gradient(at top right, rgb(255, 204, 133) 0%, rgb(228, 138, 12) 60%, rgb(127, 62, 5) 120%);
        border: 2px #fff solid;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
      }

      &.red:after {
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 50%;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: radial-gradient(at top right, rgb(255, 116, 116) 0%, rgb(228, 12, 12) 60%, rgb(162, 5, 5) 120%);
        border: 2px #fff solid;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
      }
    }
    a {
      text-align: right;
      text-decoration: none;
      color: #1da9da;
      transition: all 0.5s ease-out;
      align-self: center;
      text-align: right;
      flex:1 ; 
      &:hover {
        transition: all 0.5s ease-out;
        color: #0b6180;
      }
    }
  }
}
