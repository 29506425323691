.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  // background: rgb(255, 255, 255);
  // background: linear-gradient(to bottom, rgb(29, 169, 218) 190px, rgba(255, 255, 255, 1) 190px);
  margin-bottom: 20px;

  .home-container {
    margin-top: 20px;
    display: flex;
    max-width: 780px;
    background: rgba(14, 91, 118, 0.12);
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0 5px 2px rgba(42, 85, 100, 0.27), inset 0 0 0px 4px rgba(255, 255, 255, 0.15);
    border-radius: 5px;
  }

  .footer {
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.46);
    padding: 10px 0;
    a {
      transition: all 0.3s ease-out;
      text-decoration: none;
      color: #1b9fcd;
      &:hover {
        color: darken(#1b9fcd, 20);
      }
    }
  }
}

.load-page,
.load-page-off {
  min-width: 100%;
  height: 100%;
  background-color: #ffb101;
}

.background-load-before {
  background-color: #ffb101;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  background-image: url("../../imgs/bgloading.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.background-opacity {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  background-color: #00000044;
}

.icon-load {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    color: #000;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    animation: h1-transition 0.4s forwards ease-in-out;
    font-weight: 500;
    animation-delay: 0.4s;
    visibility: hidden;
    text-align: center;
  }
}

.load-page-off {
  display: none;
  animation: opacity-transition 0.35s forwards ease-in-out;
}

@keyframes h1-transition {
  from {
    position: relative;
    top: 2rem;
    opacity: 0.2;
  }
  to {
    position: relative;
    top: 0rem;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes opacity-transition {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

#animate-icon {
  filter: drop-shadow(2px 2px 4px #00000099);
  -webkit-animation: ring 8s 0.1s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s 0.1s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s 0.1s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
