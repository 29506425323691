.charge-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 10px;

  &.no-installments {
    .box-section:last-child {
      grid-area: auto;
    }
  }

  h1 {
    span {
      float: right;
      color: darken(rgb(29, 169, 218), 15%);
    }
  }

  .box-section {
    margin-top: 0;

    .box-section-content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      div:nth-child(2) {
        text-align: right;
      }

      &:nth-child(n) {
        span {
          font-size: 0.7em;
        }
        p {
          margin: 0;
          padding: 0;

          &.valor-boleto {
            font-size: 1.8em;
            line-height: 0.8em;
            font-weight: 700;
          }
          &.data-vencimento {
            font-size: 1.8em;
            line-height: 0.8em;
          }
        }
      }
    }

    &.installments-section:last-child {
      grid-area: 1 / 2 / 3 / 3;
      .box-section-content {
        display: block;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-top: 10px;
          li {
            padding: 5px 5px;
            display: flex;
            border-bottom: 1px rgba(0, 0, 0, 0.097) solid;
            font-size: 1em;

            div:hover {
              background-color: #d3d3d325;
              border-radius: 3px;
            }

            div {
              display: flex;
              align-items: center;
              cursor: pointer;
              animation: all 0.2s ease-in-out;

              span {
                font-size: 0.7em;
                opacity: 0.76;
                vertical-align: middle;
              }
              &:first-child {
                flex: 1;
                .icon-calender {
                  margin: 0 5px;
                  opacity: 0.35;
                }
              }
              &:last-child {
                text-align: right;
              }
            }

            &.current {
              border: 1px #1da9da solid;
              box-shadow: 1px 1px 0 0px rgba(0, 0, 0, 0.145);
              background: #fff;
              border-radius: 5px;
              font-weight: 600;
              div {
                .icon-calender {
                  opacity: 1;
                  color: darken(#1da9da, 20%);
                }
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      .box-section-content {
        display: flex;
        flex-direction: column;
        span {
          margin-top: 15px;
        }
        &:nth-child(n) {
          flex: 1;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .charge-info {
    grid-template-columns: repeat(1, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    .box-section {
      &:last-child {
        grid-area: auto;
      }
    }
  }
}

@media print {
  .charge-info {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: auto;
    border: 1px rgba(0, 0, 0, 0.378) solid !important;
    .box-section {
      &:last-child {
        grid-area: 1 / 2 / 3 / 3 !important;
      }
    }
  }
}
