.header {
  color: #fff;
  .logo-container {
    display: none;
    width: 200px;
    height: 150px;
    float: left;
    margin-right: 20px;

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
    &.show-logo {
      display: block;
      // margin-right: 20px;
    }
  }
  h1 {
    font-size: 1.3em;
    margin: 0;
  }
  span {
    font-size: 0.9em;
    margin-bottom: 20px;
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: 0.8em;
    }
  }
}
