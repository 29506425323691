//Box section para todos os components 
.box-section {
  background-color: rgb(250, 250, 250);
  border-radius: 3px;
  display: block;
  padding: 20px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1), inset 0 0 0px 4px rgba(255, 255, 255, 0.45);
  color: rgba(0, 0, 0, 0.77);
  font-size: 0.9em;
  margin-top: 10px;

  h1 {
    margin-top: 0;
    font-size: 1.25em;
    opacity: 0.66;
  }

  h2 {
    font-size: 1.25em;
    opacity: 0.66;
  }

  .btn {
    background: #1da9da;
    padding: 10px 15px;
    border: none;
    box-sizing: border-box;
    color: #fff;
    box-shadow: 1px 1px 0 0 darken(#1da8da9f, 30), inset 0 0 0 2px rgba(255, 255, 255, 0.433);
    border-radius: 5px;
    margin-right: 15px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9em;
    
    &:hover {
      box-shadow: 1px 1px 0 0 darken(#1da8da9f, 30), inset 0 0 1px 3px rgba(255, 255, 255, 0.433);
      background: lighten(#1da9da, 15);
    }
  }
}

@media print {
  * {
    color: #000 !important;
    background: none !important;
    box-shadow: none !important;

    &.current {
      border: 1px #000 solid !important;
    }
    .content-container {
      .box-section:nth-child(4) {
        display: none !important;
      }
      .charge-info {
        grid-template-columns: repeat(2, 1fr) !important;
        border: 1px rgba(0, 0, 0, 0.378) solid !important;
        .box-section {
          &:last-child {
            grid-area: 1 / 2 / 3 / 3 !important;
          }
        }
      }

      & > .payment-status .box-section-content {
        a {
          display: none;
        }
        p {
          padding-left: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.box-section-payment {
  background-color: rgb(250, 250, 250);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1), inset 0 0 0px 4px rgba(255, 255, 255, 0.45);
  color: rgba(0, 0, 0, 0.77);
  font-size: 0.9em;
  margin-top: 10px;

  h1 {
    margin-top: 0;
    font-size: 1.25em;
    opacity: 0.66;
  }

  h2 {
    font-size: 1.25em;
    opacity: 0.66;
  }

  .btn {
    background: #1da9da;
    padding: 10px 15px;
    border: none;
    box-sizing: border-box;
    color: #fff;
    box-shadow: 1px 1px 0 0 darken(#1da8da9f, 30), inset 0 0 0 2px rgba(255, 255, 255, 0.433);
    border-radius: 5px;
    margin-right: 15px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9em;
    
    &:hover {
      box-shadow: 1px 1px 0 0 darken(#1da8da9f, 30), inset 0 0 1px 3px rgba(255, 255, 255, 0.433);
      background: lighten(#1da9da, 15);
    }
  }
}

@media print {
  * {
    color: #000 !important;
    background: none !important;
    box-shadow: none !important;

    &.current {
      border: 1px #000 solid !important;
    }
    .content-container {
      .box-section-payment:nth-child(4) {
        display: none !important;
      }
      .charge-info {
        grid-template-columns: repeat(2, 1fr) !important;
        border: 1px rgba(0, 0, 0, 0.378) solid !important;
        .box-section-payment {
          &:last-child {
            grid-area: 1 / 2 / 3 / 3 !important;
          }
        }
      }

      & > .payment-status .box-section-content {
        a {
          display: none;
        }
        p {
          padding-left: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}