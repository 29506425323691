.payment-type {
  .payment-buttons {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      flex: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .payment-form {
    // display: grid;
    // grid-template-columns: 1fr 1fr;

    > div:nth-child(n) {
      min-height: 300px;
    }

    .pix-container {
      display: none;
    }

    .boleto-container {
      background: url(../../imgs/boleto.jpg) no-repeat center;
      display: none;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-end;
      &.show {
        display: flex;
      }

      h1 {
        flex: 1;
        font-size: 0.9em;
        width: 100%;
        min-height: 254px;
      }

      .btn-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        button {
          flex: 1;
          align-self: flex-end;

          &:nth-child(2) {
            margin-right: 0;
          }
          &:last-child {
            margin-top: 10px;
            margin-right: 0;
            flex-basis: 100%;
          }
        }
      }
    }
  }
}

.content-payment-type {
  margin-top: 10px;
  background-color: #fafafa;
  padding: 20px;
  color: rgba(0, 0, 0, 0.77);
  box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 10%), inset 0 0 0px 4px rgb(255 255 255 / 45%);
}

.payment-type {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.77);

  #title-selecione {
    font-size: 1.25em;
    opacity: 0.35;
  }
}

.padding-btn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80%;

  .span-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 2.5rem;
    border: none;
    background-image: linear-gradient(to bottom, rgb(0, 0, 255), rgb(35, 35, 129));
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.453);
    border-radius: 2px;
  }

  #margin-right {
    margin-right: 0.5rem;
  }
}

.payment-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.77);
}

.background-btn {
  display: flex;
  width: 50%;
  height: 100%;
}

.padding-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    color: #f3f3f3;
  }
}

.type-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content-type-payment {
  display: flex;
  flex-direction: column;

  .title-payment h2 {
    font-size: 1.25em;
    opacity: 0.35;
    text-align: center;
    // margin-left: 0.5rem;
    // margin-right: 0.5rem;
  }

  .generate-boleto {
    display: flex;
    align-items: center;
    justify-content: center;

    .padding-btn-payment {
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;

      .btn-type {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        text-decoration: none;
        color: #fafafa;
        background-image: linear-gradient(to right, rgb(0, 0, 255), rgb(35, 35, 129));
        padding: 0.5rem;
        border-radius: 2px;
        width: 10rem;
        cursor: pointer;
        font-size: 1rem;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.453);
      }
    }
  }

  .payment-buttons-boleto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .padding-btn-payment {
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;

      .btn-type {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        text-decoration: none;
        color: #fafafa;
        background-image: linear-gradient(to right, rgb(0, 0, 255), rgb(35, 35, 129));
        padding: 0.5rem;
        border-radius: 2px;
        width: 10rem;
        cursor: pointer;
        font-size: 1rem;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.453);
        animation: animate-btn-boleto 0.5s forwards ease-in-out;
        visibility: hidden;
      }

      @keyframes animate-btn-boleto {
        from {
          position: relative;
          top: 4rem;
          left: 0rem;
          opacity: 0;
        }
        to {
          position: relative;
          top: 0rem;
          left: 0rem;
          opacity: 1;
          visibility: visible;
        }
      }

      #margin-right {
        margin-right: 0.35rem;
      }
    }
  }
}

.content-modal-copy {
    select {
      border: none;
      border-radius: 3px;
      box-shadow: 1px 2px 4px 1px #00000033;
      cursor: pointer;
      padding: 0.5rem;
      width: 40%;
    }

    select:focus {
      outline: none;
    }

    button{
      width: 40%;
      padding: 0.5rem;
      border: none;
      font-size: 1rem;
      font-weight: 400;
      border-radius: 3px;
      background-color: #000fff;
      color: #fff;
      cursor: pointer;
      box-shadow: 1px 2px 4px 1px #000fff33;
    }
}

#padding{
  padding-bottom: 1rem;
}

.generate-boleto-sucess {
  display: none;
}

.payment-buttons-boleto-off {
  display: none;
}

.flags-card {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;

  span {
    color: #323232;
    text-align: center;
    padding-bottom: 0.35rem;
  }

  .flags {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    padding: 0.25rem;
    margin: 0px;

    img {
      margin-left: 0.15rem;
      margin-right: 0.15rem;
    }
  }
}

.form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
  }
}

.input-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 0.65rem;
  padding-top: 0.65rem;

  label {
    text-align: center;
    align-self: initial;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    padding-bottom: 0.15rem;
    color: rgba(0, 0, 0, 0.584);
    font-weight: 500;
  }

  input {
    border: none;
    text-align: center;
    text-transform: uppercase;
    padding: 0.65rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    width: 80%;
  }

  input:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    text-align: center;
    text-transform: initial;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}

.container-modal-copy {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 199999;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
}

.flex-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 0.65rem;
  padding-top: 0.65rem;

  label {
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    padding-bottom: 0.15rem;
    color: rgba(0, 0, 0, 0.584);
    font-weight: 500;
  }

  input {
    text-align: center;
    border: none;
    padding: 0.65rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    width: 60%;
  }

  input:focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}

.input-form-card-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.padding-btn-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.padding-btn-top input[type="submit"],
input[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  color: #fafafa;
  background-image: linear-gradient(to right, rgb(0, 0, 255), rgb(35, 35, 129));
  padding: 0.5rem;
  border-radius: 2px;
  width: 86%;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.453);
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.payment-buttons-off {
  display: none;
}

.card-off {
  display: none;
}

.boleto-off {
  display: none;
}

#off-brand{
  opacity: 0.4;
}

#active-brand{
  opacity: 1;
}

.content-payment-type-none {
  display: none;
}

.content-modal-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25rem;
  height: 15rem;
  background-color: #ebebeb;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.453);

  #margin-right {
    margin-right: 0.5rem;
  }

  #rotate {
    // padding-bottom: 1rem;
    animation: is-rotating 1s infinite;
  }

  @keyframes is-rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  span {
    padding-top: 1rem;
  }
}

.content-modal-copy {
  animation: animate-copy 0.6s forwards ease-in-out;
}

@keyframes animate-copy {
  from {
    position: relative;
    top: 30%;
    right: 4rem;
    opacity: 0;
  }
  to {
    position: relative;
    top: 30%;
    right: 0rem;
    opacity: 1;
  }
}

.modal-off {
  display: none;
}

@media (max-width: 740px) {
  .payment-buttons {
    flex-direction: column;
    align-items: center;
  }
  .padding-btn .span-type {
    width: 95%;
  }
  .payment-type #title-selecione {
    font-size: 1.25em;
    opacity: 0.35;
    justify-content: center;
    align-items: center;
  }
  #title-selecione {
    text-align: center;
  }

  .padding-btn-top {
    padding-bottom: 1rem;
  }
}

@media (max-width: 520px) {
  .content-modal-copy {
    top: 30%;
    width: 90%;
  }
}

@media (max-width: 430px) {
  .flags-card {
    .flags {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-flow: row;
      padding: 0.25rem;
      margin: 0px;
    }
  }
  input[type="submit"] {
    width: 92%;
  }
  .padding-btn .span-type {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .content-modal-copy {
    flex-direction: column;
    top: 30%;
    width: 90%;
    text-align: center;
    padding: 0.5rem;

    #margin-right {
      margin-right: 0rem;
      padding-bottom: 1rem;
    }
  }
}

@media print {
  .payment-type {
    display: none !important;
  }
}
